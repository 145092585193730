<template>
  <div id="capital-predict">
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            :colon="false">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight"
                       :label-col="{ span: 8 }"
                       :wrapper-col="{ span: 12 }">
            <a-input :disabled="true"
                     :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

      </a-row>

      <!-- 回款计划 -->
      <!-- 1- 总承包项目资金预测表-回款计划,0-总承包项目资金预测表-分包支付款计划-->
      <ReturnTable title="总承包项目资金预测表-回款计划"
                   :prj-name="msg.projectName"
                   @change="getTableValue"
                   :defaultData="initTableObj['return']" />

      <Padding size='large' />

      <!-- 付款计划 -->
      <!-- 1- 总承包项目资金预测表-回款计划,0-总承包项目资金预测表-分包支付款计划-->
      <PayTable title="总承包项目资金预测表-分包支付款计划"
                :prj-name="msg.projectName"
                @change="getTableValue"
                :defaultData="initTableObj['pay']" />

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit"
                    type="primary"
                    v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import PayTable from "./components/pay-table.vue";
import ReturnTable from "./components/return-table.vue";

import { fetchDetail } from "@/api/epc";
import {
  submit,
  fetchList,
  downloadTemplate,
  exportFile,
} from "@/api/epc/income-expense/capitalPredict.js";
import { saveAs } from "file-saver";
import { groupBy, recoveryArr } from "@/utils/groupBy";

import { mapGetters } from "vuex";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    PayTable,
    ReturnTable,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      stage: "epc_balance_fund_forecast",

      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      projectName: "",
      designCode: "",

      // 编辑字段
      origList: [],
      hid: "",

      initTableObj: {}, //回款计划和付款计划的表格 初始数据
      tableObj: {}, //回款计划和付款计划的表格 修改后的数据
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),

    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, isView, hid } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;
    this.hid = hid || "";

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getDetail() {
      fetchList({ pid: this.pid })
        .then((res) => {
          const list = res.list;
          if (list.length < 1) {
            return;
          }
          this.form.setFieldsValue({
            contractName: list[0].contractName,
          });

          // 回显回款计划与付款计划
          this.initTableObj = groupBy(list, "type");
          this.origList = list;
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    getTableValue(value) {
      // 根据type，替换选择的文件
      this.tableObj[value.type] = value.list;
      console.log(this.tableObj);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          // 如果没有id ，则新增
          // 表格数据转换为传参的格式
          for (let key in this.tableObj) {
            this.tableObj[key].forEach((item) => {
              delete item.key;
              item.type = key;
            });
          }

          let paramList = recoveryArr(this.tableObj);
          paramList.forEach((item) => {
            item.pid = this.msg.pid;
            item.projectName = this.msg.projectName;
            item.designCode = this.msg.designCode;
          });

          console.log(paramList);

          // 找到删除的元素
          this.origList.forEach((item) => {
            let index = paramList.findIndex((ele) => ele.id === item.id);
            if (index < 0) {
              item.delStatus = 1;
            }
          });
          paramList.push(
            ...this.origList.filter((item) => item.delStatus === 1)
          );

          console.log(paramList);
          submit({ list: paramList, historyId: this.hid }).then(() => {
            if (!this.id) {
              //添加
              this.form.resetFields();
              this.initTableObj = { pay: [], return: [] };
            } else {
              //修改
              this.getDetail();
            }
          });
        }
      });
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>
<style lang="less">
#capital-predict {
  .ant-table-wrapper {
    width: 1071px;
  }
  .ant-input-group-addon {
    padding: 0;
  }
}
</style>