<template>
  <a-modal
    :visible="visible"
    :title="control === 'add' ? '开票' : '修改发票'"
    :footer="null"
    @cancel="cancel"
  >
    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      @submit="handleSubmit"
    >
      <a-form-item label="发票号码">
        <a-input v-decorator="['code', {
            initialValue: detail.code,
          }]" />
      </a-form-item>

      <a-form-item label="发票类型">
        <a-radio-group
          v-decorator="['type', {
            initialValue: detail.type,
          }]"
          button-style="solid"
        >
          <a-radio-button
            v-for="item in invoiceTypeList"
            :key="item.value"
            :value="item.value"
          >{{ item.name }}</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="开票日期">
        <a-date-picker
          placeholder
          v-decorator="['date', {
            initialValue: detail.date ? moment(  detail.date) : undefined,
          }]"
          style="width: 100%"
        />
      </a-form-item>

      <a-form-item label="税点">
        <a-input-number
          :min="0"
          :max="100"
          :formatter="$ratioFormatter"
          v-decorator="['ratio', {
            initialValue: detail.ratio
          }]"
          style="width: 100%"
        />
      </a-form-item>

      <a-form-item label="合计金额(元)">
        <a-input-number
          :formatter="$inputNumberFormat"
          style="width: 100%"
          v-decorator="[
            'amount',
            { 
                initialValue: detail.amount,
                rules: [{ required: true, message: '请输入！' }] },
          ]"
        />
      </a-form-item>

      <a-form-item>
        <span slot="label" class="required">附件</span>
        <FileUpload @uploaded="uploaded">
          <a-button type="primary" icon="upload">上传附件</a-button>
        </FileUpload>

        <div class="link-list">
          <div class="link" v-for="(item, index) in fileList" :key="index">
            <a target="_blank" download :href="item.completePath">{{ item.name }}</a>
            <div class="control" @click="deleteFile(item)">
              <a-icon type="delete" />
            </div>
          </div>
        </div>
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import FileUpload from "@/components/file-upload";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    control: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  components: {
    FileUpload
  },

  watch: {
    detail: {
      handler() {
        this.fileList = Array.isArray(this.detail.attachments)
          ? [...this.detail.attachments]
          : [];
      },
      deep: true
    }
  },

  data() {
    return {
      form: this.$form.createForm(this),
      fileList: []
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    invoiceTypeList() {
      return this.findDataDict("invoiceType");
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel");
      this.form.resetFields();
      this.fileList = [];
    },
    moment,

    handleSubmit(e) {
      e.preventDefault();
      if (!this.fileList.length) {
        this.$message.error("请上传附件！");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          const date = values.date?.format("YYYY-MM-DD");

          const params = {
            ...this.detail,
            ...values,
            date,
            attachments: this.fileList,
            category: this.category,
            delStatus: 0
          };

          if (this.control === "add") {
            this.list.push({
              ...params,
              key: Date.now().toString() + Math.random()
            });
            this.$emit("change", this.list);
          } else {
            const index = this.list.findIndex(
              item => item.key === this.detail.key
            );
            this.list.splice(index, 1, params);
            this.$emit("change", this.list);
          }

          this.cancel();
        }
      });
    },

    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },

    deleteFile(item) {
      const index = this.fileList.findIndex(file => file.id === item.id);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.link-list {
  .link {
    margin: 4px 0;
    padding: 0 12px;
    background-color: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .control {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .control:hover {
      color: #1890ff;
    }

    a {
      line-height: 1.5em;
    }
  }
}
</style>
