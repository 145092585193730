<template>
  <div id="capital-predict-table">
    <div class="head-title">回款计划</div>

    <div class="align-center table-btn">
      <a-button icon="plus" @click="addtable" Search> 新增 </a-button>
    </div>

    <a-table
      :dataSource="list"
      :pagination="false"
      bordered
      :columns="columns"
      :locale="locale"
    >
      <template #title>
        <div class="title-container">
          <span class="title">{{ title }}</span>
          <span class="unit">单位：元</span>
        </div>
      </template>

      <template slot="departName" slot-scope="text, record">
        <!-- 部门选择器 -->
        <a-select
          style="width: 100%"
          :value="record.departName"
          @change="
            (val) => {
              deptChange(val, record);
            }
          "
        >
          <a-select-option
            v-for="item in organizationList"
            :key="item.id"
            :value="item.id + ',' + item.name"
            >{{ item.uniqueName }}</a-select-option
          >
        </a-select>
      </template>

      <template slot="key" slot-scope="text">
        {{ text }}
      </template>

      <template slot="org" slot-scope="text, record">
        <a-input v-model="record.org" @change="onInputChange" />
      </template>

      <template slot="planPrjName" slot-scope="text, record">
        <a-input :value="record.planPrjName" :disabled="true" />
      </template>

      <template slot="weekFst" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.weekFst"
          @change="onInputChange"
        />
      </template>

      <template slot="weekSnd" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.weekSnd"
          @change="onInputChange"
        />
      </template>

      <template slot="weekTrd" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.weekTrd"
          @change="onInputChange"
        />
      </template>

      <template slot="weekFth" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.weekFth"
          @change="onInputChange"
        />
      </template>

      <template slot="weekFth" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.weekFth"
          @change="onInputChange"
        />
      </template>

      <!-- 月度title -->
      <div slot="month" class="month-title">
        <a-month-picker
          @change="onInputChange"
          :allowClear="false"
          style="width: 100%"
          v-model="curMonth"
        />
      </div>

      <template slot="nextMonth" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.nextMonth"
          @change="onInputChange"
        />
      </template>

      <template slot="afterNextMonth" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.afterNextMonth"
          @change="onInputChange"
        />
      </template>

      <template slot="delete" slot-scope="text, record">
        <a-popconfirm title="是否要删除此行？" @confirm="removeMember(record)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <div class="test flex">
      <div style="width: 600px; border-left: 1px solid #e8e8e8">合计</div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.weekFst)"
      >
        {{ $numberFormat(totalObj.weekFst) }}
      </div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.weekSnd)"
      >
        {{ $numberFormat(totalObj.weekSnd) }}
      </div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.weekTrd)"
      >
        {{ $numberFormat(totalObj.weekTrd) }}
      </div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.weekFth)"
      >
        {{ $numberFormat(totalObj.weekFth) }}
      </div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.nextMonth)"
      >
        {{ $numberFormat(totalObj.nextMonth) }}
      </div>
      <div
        style="width: 70px"
        class="text-hidden"
        :title="$numberFormat(totalObj.afterNextMonth)"
      >
        {{ $numberFormat(totalObj.afterNextMonth) }}
      </div>
      <div style="width: 50px"></div>
    </div>
  </div>
</template>
<script>
import organization from "@/mixins/organization";
import moment from "moment";

export default {
  mixins: [organization],

  props: {
    title: {
      type: String,
      default: "",
    },
    // 初始值
    defaultData: {
      type: Array,
      default: () => [],
    },
    prjName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      locale: { emptyText: " " }, //设置空数据的展示
      columns: [
        {
          title: "序号",
          dataIndex: "key",
          width: 50,
          align: "center",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "部门",
          dataIndex: "departName",
          width: 150,
          align: "center",
          scopedSlots: { customRender: "departName" },
        },
        {
          title: "分包单位",
          dataIndex: "org",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "org" },
        },
        {
          title: "工程名称",
          dataIndex: "planPrjName",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "planPrjName" },
        },
        {
          align: "center",
          slots: { title: "month" }, //表头插槽
          children: [
            {
              title: "第一周",
              dataIndex: "weekFst",
              key: "weekFst",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "weekFst" },
            },
            {
              title: "第二周",
              dataIndex: "weekSnd",
              key: "weekSnd",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "weekSnd" },
            },
            {
              title: "第三周",
              dataIndex: "weekTrd",
              key: "weekTrd",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "weekTrd" },
            },
            {
              title: "第四周",
              dataIndex: "weekFth",
              key: "weekFth",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "weekFth" },
            },
          ],
        },
        {
          title: "下月",
          dataIndex: "nextMonth",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "nextMonth" },
        },
        {
          title: "第三月",
          dataIndex: "afterNextMonth",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "afterNextMonth" },
        },
        {
          title: "操作",
          dataIndex: "delete",
          width: 50,
          align: "center",
          scopedSlots: { customRender: "delete" },
        },
      ],
      curMonth: null,

      totalObj: {
        weekFst: 0,
        weekSnd: 0,
        weekTrd: 0,
        weekFth: 0,
        nextMonth: 0,
        afterNextMonth: 0,
      },
    };
  },
  computed: {
    type() {
      return this.title === "总承包项目资金预测表-回款计划" ? "return" : "pay";
    },
  },

  watch: {
    // 初始值异步获取到，因此监听初始值，并设置初始值
    defaultData(val) {
      console.log(val);
      this.list = val;
      // 添加key
      this.list.forEach((item, index) => {
        item.key = index + 1;
      });
      // 添加月份
      this.curMonth = val[0] ? moment(val[0].curMonth) : "";

      // 计算合计
      this.calcTotal();

      // 变化后的list传递给父组件
      this.$emit("change", {
        type: this.type,
        list: this.list,
      });
    },
  },

  methods: {
    addtable() {
      this.list.push({
        key: this.list.length + 1,
        departName: "",
        org: "",
        planPrjName: this.prjName,
        nextMonth: null, //下月金额
        afterNextMonth: null, //第三月金额
        weekFst: null,
        weekSnd: null,
        weekTrd: null,
        weekFth: null,
      });
    },
    // 修改list内部字段：增加第一月的月份字段
    addCurMonth() {
      // 首次输入月份字段
      if (!this.list.length) {
        this.addtable();
      }
      this.list.forEach((item) => {
        item.curMonth = this.curMonth ? this.curMonth.format("YYYY-MM") : "";
      });
    },

    // 计算合计子项
    calcTotal() {
      for (const key in this.totalObj) {
        this.totalObj[key] = this.list.reduce((pre, cur) => {
          return this.$calc.expr(`${pre || 0}+${cur[key] || 0}`);
        }, 0);
      }
    },

    onInputChange() {
      this.addCurMonth();
      this.calcTotal();

      this.$emit("change", {
        type: this.type,
        list: this.list,
      });
    },

    removeMember(row) {
      this.addCurMonth();

      this.list.splice(row.key - 1, 1);
      // 重置key
      this.list.forEach((item, index) => {
        item.key = index + 1;
      });

      console.log(this.list);
      this.calcTotal();

      this.$emit("change", {
        type: this.type,
        list: this.list,
      });
    },

    deptChange(val, record) {
      console.log(val, record);
      const depardId = val.split(",")[0];
      const departName = val.split(",")[1];
      record.departId = depardId;
      record.departName = departName;

      this.addCurMonth();

      this.$emit("change", {
        type: this.type,
        list: this.list,
      });
    },
  },
};
</script>
<style lang="less" scoped>
#capital-predict-table {
  width: 1070px;

  .head-title {
    border-left: 4px solid #1890ff;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  .title-container {
    text-align: center;
    padding: 5px;
    position: relative;

    .title {
      font-size: 1.2em;
      font-weight: bolder;
    }
    .unit {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #1890ff;
    }
  }
  .test {
    width: 1070px;
    margin: 0 auto;
    div {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      text-align: center;
      padding: 5px;
    }
  }
}
// 修改默认input的样式
.ant-input {
  padding: 2px;
  margin-right: 2px;
}
</style>
<style lang="less">
#capital-predict-table {
  margin: 0 auto;
  // 按钮样式
  .table-btn > .ant-btn {
    border: none;
  }
  .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-input {
    padding: 2px;
    margin-right: 2px;
    border: none;
    text-align: center;
  }

  // 防止部门字段溢出
  .ant-select {
    max-width: 149px;
  }

  .ant-input-number {
    width: 60px;
    border: none;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      padding: 0;
      text-align: center;
    }
  }
  .ant-select-selection {
    border: none;
  }
}
</style>
