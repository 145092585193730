<template>
  <div class="sb-pay-table">
    <div class="align-center table-btn">
      <a-button icon="plus" @click="addtable" Search> 新增 </a-button>
    </div>

    <a-table
      :dataSource="list"
      :pagination="false"
      bordered
      :columns="columns"
      :locale="locale"
    >
      <template #title>
        <div class="title-container">
          <span class="title">{{ title }}</span>
          <span class="unit">单位：元</span>
        </div>
      </template>

      <template slot="itemName" slot-scope="text, record">
        <a-input v-model="record.itemName" @change="onInputChange" />
      </template>

      <template slot="payableAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.payableAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="prePayAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.prePayAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="watEleAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.watEleAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="grtAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.grtAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="sctAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.sctAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="otherAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.otherAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="sum" slot-scope="text, record">
        {{ record.sum }}
      </template>

      <template slot="paidAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.paidAmt"
          @change="onInputChange"
        />
      </template>

      <template slot="delete" slot-scope="text, record">
        <a-popconfirm title="是否要删除此行？" @confirm="removeMember(record)">
          <a>删除</a>
        </a-popconfirm>
      </template>
    </a-table>

    <div class="row flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">合计</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.payableAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.prePayAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.watEleAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.grtAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.sctAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.otherAmt) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.sum) }}</div>
      <div style="width: 70px">{{ $numberFormat(totalObj.paidAmt) }}</div>
      <div style="width: 50px"></div>
    </div>

    <div class="row flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">
        本次实付金额(元)
      </div>
      <div style="width: 210px">{{ $numberFormat(totalObj.paidAmt) }}</div>
      <div style="width: 140px">累计已付金额(元)</div>
      <div style="width: 260px">{{ $numberFormat(sumObj.totalPaidAmt) }}</div>
    </div>

    <div class="row flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">
        本次实付百分比(%)
      </div>
      <div style="width: 210px">{{ sumObj.paidRatio }}</div>
      <div style="width: 140px">累计付款百分比(%)</div>
      <div style="width: 260px">{{ sumObj.totalPaidRatio }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    // 初始值
    defaultData: {
      type: Array,
      default: () => [],
    },

    sumObj: {
      //包含本次实付金额，累计已付金额，及其百分比
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
      locale: { emptyText: " " }, //设置空数据的展示
      columns: [
        {
          title: "序号",
          dataIndex: "key",
          width: 50,
          align: "center",
        },
        {
          title: "款项名称",
          dataIndex: "itemName",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "itemName" },
        },
        {
          title: "本次应付金额",
          dataIndex: "payableAmt",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "payableAmt" },
        },
        {
          title: "应扣款项",
          align: "center",
          children: [
            {
              title: "预付款",
              dataIndex: "prePayAmt",
              key: "prePayAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "prePayAmt" },
            },
            {
              title: "水电费",
              dataIndex: "watEleAmt",
              key: "watEleAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "watEleAmt" },
            },
            {
              title: "质保金",
              dataIndex: "grtAmt",
              key: "grtAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "grtAmt" },
            },
            {
              title: "安全保证金",
              dataIndex: "sctAmt",
              key: "sctAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "sctAmt" },
            },
            {
              title: "其他",
              dataIndex: "otherAmt",
              key: "otherAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "otherAmt" },
            },
            {
              title: "合计",
              dataIndex: "sum",
              key: "sum",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "sum" },
            },
          ],
        },
        {
          title: "本次实付金额",
          dataIndex: "paidAmt",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "paidAmt" },
        },
        {
          title: "操作",
          dataIndex: "delete",
          width: 50,
          align: "center",
          scopedSlots: { customRender: "delete" },
        },
      ],
      totalObj: {
        payableAmt: 0,
        prePayAmt: 0,
        watEleAmt: 0,
        grtAmt: 0,
        sctAmt: 0,
        otherAmt: 0,
        sum: 0,
        paidAmt: 0,
      },
    };
  },
  watch: {
    // 初始值异步获取到，因此监听初始值，并设置初始值
    defaultData(val) {
      this.list = val;
      // 添加key
      this.list.forEach((item, index) => {
        item.key = index + 1;
      });

      // 计算合计
      this.calcTotal();

      // 变化后的list传递给父组件
      this.$emit("change", this.list);
    },
  },

  methods: {
    addtable() {
      const length = this.list.length;
      this.list.push({
        key: length + 1,
        itemName: "",
        payableAmt: null,
        sctAmt: null,
        otherAmt: null,
        sum: null,
        prePayAmt: null,
        watEleAmt: null,
        grtAmt: null,
        paidAmt: null,
      });
    },
    // 修改list内部字段：total
    changeList() {
      this.list.forEach((item) => {
        item.sum =
          Number(item.prePayAmt || "") +
          Number(item.watEleAmt || "") +
          Number(item.grtAmt || "") +
          Number(item.sctAmt || "") +
          Number(item.otherAmt || "");
      });
    },

    // 计算合计子项
    calcTotal() {
      for (const key in this.totalObj) {
        this.totalObj[key] = this.list.reduce((pre, cur) => {
          return pre + cur[key];
        }, 0);
        // 计算本次实付百分比，累计已付金额，付款百分比
        this.$emit("totalChange", this.totalObj.paidAmt);
      }
    },

    onInputChange() {
      this.changeList();
      this.calcTotal();

      this.$emit("change", this.list);
    },

    removeMember(row) {
      this.list.splice(row.key - 1, 1);
      // 重置key
      this.list.forEach((item, index) => {
        item.key = index + 1;
      });

      this.calcTotal();

      this.$emit("change", this.list);
    },
  },
};
</script>
<style lang="less" scoped>
.sb-pay-table {
  width: 911px;
  .title-container {
    text-align: center;
    padding: 5px;
    position: relative;

    .title {
      font-size: 1.2em;
      font-weight: bolder;
    }
    .unit {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #1890ff;
    }
  }

  .row {
    width: 910px;
    margin: 0 auto;
    div {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      text-align: center;
      padding: 5px;
    }
  }
}
</style>

<style lang="less">
.sb-pay-table {
  // 按钮样式
  margin: 0 auto;

  .table-btn > .ant-btn {
    border: none;
  }
  .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-input {
    padding: 2px;
    margin-right: 2px;
    border: none;
    text-align: center;
  }
  .ant-input-number {
    width: 60px;
    border: none;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      padding: 0;
      text-align: center;
    }
  }
}
</style>
