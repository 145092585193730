<template>
  <div style="height: 100%" id="contract-detail-doughnut"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list: {
      handler() {
        this.draw();
      },
      deep: true
    }
  },

  mounted() {
    this.chart = echarts.init(
      document.getElementById("contract-detail-doughnut")
    );
    this.draw();
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    draw() {
      this.chart?.clear();
      const that = this;

      let option = {
        tooltip: {
          trigger: "item"
        },
        legend: {
          orient: "vertical",
          x: "right",
          top: "20%",
          left: "65%",
          formatter: function(name) {
            const item = that.list.find(item => item.name === name);
            return item
              ? `${name}：${
                  typeof item.contractAmt === "number" ? item.contractAmt : 0
                }`
              : name;
          }
        },
        series: [
          {
            center: ["35%", "50%"],
            radius: ["40%", "80%"],
            name: "合同金额明细",
            type: "pie",
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: this.list.map(item => {
              return {
                ...item,
                value:
                  typeof item.contractAmt === "number" ? item.contractAmt : 0
              };
            })
          },
          // 内层圈设置
          {
            radius: ["40%", "50%"],
            center: ["35%", "50%"],
            type: "pie",
            animation: false,
            tooltip: {
              show: false
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  color: "rgba(250,250,250,0.3)"
                }
              }
            ]
          },
          // 外层圈设置
          {
            radius: ["70%", "80%"],
            center: ["35%", "50%"],
            type: "pie",
            animation: false,
            tooltip: {
              show: false
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  color: "rgba(250,250,250,0.3)"
                }
              }
            ]
          }
        ]
      };

      this.chart?.setOption(option);
    }
  }
};
</script>