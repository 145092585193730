<template>
  <a-modal
    title="开票详情"
    :visible="!!selectedName"
    @cancel="cancelInvoice"
    :footer="null"
    width="800px"
  >
    <div class="right">
      <a-button type="primary" @click="add">开票</a-button>
    </div>
    <Padding />

    <a-table bordered :data-source="filteredList" :pagination="false">
      <a-table-column title="发票号码" data-index="code" align="center"></a-table-column>

      <a-table-column title="发票类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="invoiceType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="开票日期" data-index="date" align="center" />
      <a-table-column title="税点" align="right">
        <template slot-scope="text">{{ text.ratio }}%</template>
      </a-table-column>
      <a-table-column title="合计金额(元)" align="right">
        <template slot-scope="text">
          <span style="color: #1890ff">
            {{
            text.amount ? text.amount.toLocaleString() : text.amount
            }}
          </span>
        </template>
      </a-table-column>
      <a-table-column title="发票附件" align="center">
        <template slot-scope="text">
          <span v-if="Array.isArray( text.attachments)">
            <span v-for="(item, index) in text.attachments" :key="index">
              <a :href="item.completePath" target="_blank">{{item.name}}</a>
              <span v-if="index !== text.attachments.length - 1">,</span>
            </span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a href="#" @click.prevent="edit(text)">修改</a>
            <a href="#" class="danger" @click.prevent="deleteText(text)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <Editor
      :visible="visible"
      :control="control"
      :detail="detail"
      :list="list"
      :category="selectedName"
      @cancel="cancel"
      @change="onChangeInvoice"
    />

    <div
      style="font-weight: bold; margin-top: 8px; font-size: 14px"
    >合计金额：{{ $numberFormat(total) }}元</div>
  </a-modal>
</template>

<script>
import Editor from "./editor.vue";
import accurate from "accurate";
export default {
  components: {
    Editor
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    selectedName: {
      type: String,
      default: ""
    },
    amtCateList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      control: "",
      detail: {}
    };
  },
  computed: {
    filteredList() {
      return this.list.filter(
        item => item.category === this.selectedName && item.delStatus !== 1
      );
    },

    total() {
      let sum = 0;
      this.filteredList.forEach(item => {
        sum = accurate.add(sum, item.amount);
      });
      return sum;
    }
  },

  methods: {
    add() {
      this.detail = {};
      this.control = "add";
      this.visible = true;
    },
    edit(text) {
      this.detail = text;
      this.control = "edit";
      this.visible = true;
    },
    cancel() {
      this.detail = {};
      this.control = "";
      this.visible = false;
    },

    deleteText(text) {
      const index = this.list.findIndex(item => item.key === text.key);
      this.list.splice(index, 1, {
        ...text,
        delStatus: 1
      });
      this.$emit("change", this.list);

      this.setValue();
    },

    cancelInvoice() {
      this.$emit("select", "");
    },

    onChangeInvoice(payload) {
      console.log("chenged invoice list");

      this.list.splice(0, this.list.length, ...payload);
      this.$emit("change", this.list);

      this.setValue();
    },

    setValue() {
      let sum = 0;
      this.filteredList.forEach(item => {
        sum = accurate.add(sum, item.amount);
      });
      console.log("sum", sum);

      const index = this.amtCateList.findIndex(
        item => item.name === this.selectedName
      );
      this.amtCateList.splice(index, 1, {
        ...this.amtCateList[index],
        invoiceAmt: sum
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input-tip {
  font-size: 12px;
  cursor: pointer;
  color: #1890ff;
}
</style>
