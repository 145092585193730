<template>
  <div class="panel" id="epc-subpackage-detail">
    <div class="label title2 bd-r-none">
      <a @click="remove" class="danger flex-1">
        <a-icon type="delete" />
      </a>
      <div class="flex-2 center flex">{{ detail.type }}分包合同编号</div>
      <span class="flex-1"></span>
    </div>
    <div class="label title3" style="padding: 0">
      <a-input
        placeholder="请输入"
        :value="detail.code"
        @change="e => onChange('code', e.target.value)"
      />
    </div>
    <div class="label">分包合同名称</div>
    <div class="value1">
      <a-input
        placeholder="请输入"
        :value="detail.name"
        @change="e => onChange('name', e.target.value)"
      />
    </div>
    <div class="label">分包单位</div>
    <div class="value2">
      <a-input
        placeholder="请输入"
        :value="detail.org"
        @change="e => onChange('org', e.target.value)"
      />
    </div>
    <div class="label">付款条件</div>
    <div class="value1">
      <a-textarea
        :auto-size="{ minRows: 1 }"
        placeholder="请输入"
        :value="detail.payCondition"
        @change="e => onChange('payCondition', e.target.value)"
      />
    </div>
    <div class="label">合同金额(元)</div>
    <div class="value2">
      <a-input-number
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.contractAmt"
        @change="value => onChange('contractAmt', value)"
      />
    </div>
    <div class="label">累计收票金额(元)</div>
    <div class="value1">
      <a-input-number
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.sumInvoiceAmt"
        @change="value => onChange('sumInvoiceAmt', value)"
      />
    </div>
    <div class="label">累计支付金额(元)</div>
    <div class="value2">
      <a-input-number
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.sumPayedAmt"
        @change="value => onChange('sumPayedAmt', value)"
      />
    </div>
    <div class="label">支付比例</div>
    <div class="value1">{{ payRatio }}%</div>
    <div class="label">待支付金额(元)</div>
    <div class="value2">
      <a-input-number
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.amtDue"
        @change="value => onChange('amtDue', value)"
      />
    </div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    list: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    payRatio() {
      if (
        typeof this.detail.sumPayedAmt === "number" &&
        typeof this.detail.contractAmt === "number"
      ) {
        return formatRatio(this.detail.sumPayedAmt, this.detail.contractAmt);
      } else {
        return 0;
      }
    }
  },

  methods: {
    onChange(key, value) {
      const index = this.list.findIndex(item => item.key === this.detail.key);
      this.list.splice(index, 1, {
        ...this.detail,
        [key]: value
      });
    },

    remove() {
      const index = this.list.findIndex(item => item.key === this.detail.key);

      const that = this;
      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          that.list.splice(index, 1, {
            ...that.detail,
            delStatus: 1
          });
          // that.$emit("change", that.list);
        }
      });
    }
  }
};
</script>

<style lang="less">
#epc-subpackage-detail {
  .ant-input {
    border-width: 0;
  }
}
</style>


<style lang="less" scoped>
.panel {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .title2 {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .title3 {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value1,
  .value2,
  .value3 {
    padding: 0;
  }
}
</style>
