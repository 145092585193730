<template>
  <div id="subpackage-pay-approval">
    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" :colon="false">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同编号" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input v-decorator="[
                            'contractCode',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]">
              <ContractSelector slot="addonAfter" @change="onSelectContract">
                <a-button type="primary"> 获取合同 </a-button>
              </ContractSelector>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="工程名称" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="合同名称">
            <a-input v-decorator="[
                            'contractName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <div class="panel">
            <div class="flex">
              1、我公司与
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input v-decorator="[
                                    'owner',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              (发包方)签订的该工程合同总价款为
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'contractAmt',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元，目前与我公司已收到工程费
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'engFeeReceived',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元，合计收到工程费
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'engFeeSum',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元。
            </div>

            <div class="flex">
              2、根据
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input v-decorator="[
                                    'contractor',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              (分包方)与我公司签订的
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input v-decorator="[
                                    'subcontractName',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              分包合同，其总价款为
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'subcontractAmt',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              已支付
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'subcontractPaidAmt',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元，根据支付方式，此次需要支付
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'amtDueCurrent',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元。扣除税费：
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'taxPayable',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              ，实际支付
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-number :formatter="$inputNumberFormat" v-decorator="[
                                    'amtDueActual',
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请输入！',
                                        },
                                      ],
                                    },
                                  ]" />
              </a-form-item>
              元，请领导审核批准。
            </div>
          </div>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="总承包事业部经办人" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <EmployeeSelector :single="true" @change="onChange1">
              <a-button block style="text-align: left">
                <div v-if="gcdOperator.name">
                  {{ gcdOperator.name }}
                </div>
                <div v-else class="input-style">
                  <span>请输入部门/姓名/拼音/联系方式</span>
                  <a-icon type="search" />
                </div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经营管理部经办人" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <EmployeeSelector :single="true" @change="onChange2">
              <a-button block style="text-align: left">
                <div v-if="mdOperator.name">
                  {{ mdOperator.name }}
                </div>
                <div v-else class="input-style">
                  <span>请输入部门/姓名/拼音/联系方式</span>
                  <a-icon type="search" />
                </div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经营管理部复核人" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <EmployeeSelector :single="true" @change="onChange3">
              <a-button block style="text-align: left">
                <div v-if="mdReviewer.name">
                  {{ mdReviewer.name }}
                </div>
                <div v-else class="input-style">
                  <span>请输入部门/姓名/拼音/联系方式</span>
                  <a-icon type="search" />
                </div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="财务部审核人" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <EmployeeSelector :single="true" @change="onChange4">
              <a-button block style="text-align: left">
                <div v-if="finAuditor.name">
                  {{ finAuditor.name }}
                </div>
                <div v-else class="input-style">
                  <span>请输入部门/姓名/拼音/联系方式</span>
                  <a-icon type="search" />
                </div>
              </a-button>
            </EmployeeSelector>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <span slot="label" class="">附件</span>
            <div class="link-list" v-decorator="[
                            'file',
                            { rules: [{ required: true, message: '请上传附件' }] },
                          ]">
              <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                                          item.name
                                          }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import ContractSelector from "@/components/contract-selector";
import EmployeeSelector from "@/components/employee-selector";

import FileUpload from "@/components/file-upload";
import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/income-expense/subpackPayApproval.js";
import { saveAs } from "file-saver";

import { mapGetters } from "vuex";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ContractSelector,
    EmployeeSelector,
    FileUpload,
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      stage: "epc_balance_subpack_expense_app",

      isView: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "", //表的id
      hid: "",

      gcdOperator: {
        name: "",
        id: "",
      },
      mdOperator: {
        name: "",
        id: "",
      },
      mdReviewer: {
        name: "",
        id: "",
      },
      finAuditor: {
        name: "",
        id: "",
      },

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),

    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.hid = hid || "";
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.form.setFieldsValue({
            contractCode: res.contractCode,
            contractName: res.contractName,
            owner: res.owner,
            contractAmt: res.contractAmt,
            engFeeReceived: res.engFeeReceived,
            engFeeSum: res.engFeeSum,
            contractor: res.contractor,
            subcontractName: res.subcontractName,
            subcontractAmt: res.subcontractAmt,
            subcontractPaidAmt: res.subcontractPaidAmt,
            amtDueCurrent: res.amtDueCurrent,
            taxPayable: res.taxPayable,
            amtDueActual: res.amtDueActual,
          });

          this.gcdOperator = {
            name: res.gcdOperatorName,
            id: res.gcdOperator,
          };
          this.mdOperator = {
            name: res.mdOperatorName,
            id: res.mdOperator,
          };
          this.mdReviewer = {
            name: res.mdReviewerName,
            id: res.mdReviewer,
          };
          this.finAuditor = {
            name: res.finAuditorName,
            id: res.finAuditor,
          };

          if (res.attachments) {
            this.form.setFieldsValue({
              file: true,
            });
            this.fileList = res.attachments;
          }
        })
        .catch();
    },

    onSelectContract(value) {
      console.log(value.id);
      this.form.setFieldsValue({
        contractCode: value.code,
        contractName: value.name,
      });
    },

    onChange1(val) {
      console.log(val);
      this.gcdOperator.name = val[0].name;
      this.gcdOperator.id = val[0].userId;
    },

    onChange2(val) {
      console.log(val);
      this.mdOperator.name = val[0].name;
      this.mdOperator.id = val[0].userId;
    },

    onChange3(val) {
      console.log(val);
      this.mdReviewer.name = val[0].name;
      this.mdReviewer.id = val[0].userId;
    },

    onChange4(val) {
      console.log(val);
      this.finAuditor.name = val[0].name;
      this.finAuditor.id = val[0].userId;
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,

              gcdOperator: this.gcdOperator.id,
              mdOperator: this.mdOperator.id,
              mdReviewer: this.mdReviewer.id,
              finAuditor: this.finAuditor.id,
            }).then(() => {
              this.form.resetFields();
              this.gcdOperator = {
                name: "",
                id: "",
              };
              this.mdOperator = {
                name: "",
                id: "",
              };
              this.mdReviewer = {
                name: "",
                id: "",
              };
              this.finAuditor = {
                name: "",
                id: "",
              };
              this.fileList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,

              gcdOperator: this.gcdOperator.id,
              mdOperator: this.mdOperator.id,
              mdReviewer: this.mdReviewer.id,
              finAuditor: this.finAuditor.id,
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.input-style {
  color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.center {
  margin-top: 80px;
}
</style>

<style lang="less">
#subpackage-pay-approval {
  .panel {
    margin: 0 8% 16px;
    padding: 0 2%;
    border: 1px solid #d9d9d9;

    .flex {
      flex-wrap: wrap;
      align-items: center;
      // margin: 0 10%;
      padding: 10px 0;

      .ant-form-item {
        display: inline-block;
        margin-bottom: 0;
        margin: 0 5px;
      }
    }
  }

  .ant-input-group-addon {
    padding: 0;
  }
}
</style>