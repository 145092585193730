var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"package-pay"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.designCode}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同编号","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractCode',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractCode',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}]},[_c('ContractSelector',{attrs:{"slot":"addonAfter"},on:{"change":_vm.onSelectContract},slot:"addonAfter"},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" 获取合同 ")])],1)],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.msg.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractName',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"分包方","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractor',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractor',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"合同额(元)","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'contractAmt',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'contractAmt',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat},on:{"change":_vm.contractAmtChange}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"付款类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'payType',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'payType',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！',\n                              },\n                            ],\n                          },\n                        ]"}]},_vm._l((_vm.payTypeList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{staticClass:"table",attrs:{"lg":24,"md":24,"sm":24}},[_c('SubPackageTable',{attrs:{"title":"总承包项目分包付款表","defaultData":_vm.list,"sumObj":_vm.sumObj,"id":_vm.id},on:{"totalChange":_vm.totalChange,"change":_vm.getSubPayList}})],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'file',
                          { rules: [{ required: true, message: '请上传附件' }] },
                        ]),expression:"[\n                          'file',\n                          { rules: [{ required: true, message: '请上传附件' }] },\n                        ]"}],staticClass:"link-list"},[_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{on:{"click":_vm.addDraft}},[_vm._v("存草稿")]):_vm._e(),(!_vm.isView)?_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }