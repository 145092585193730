// 资金预测表
import request from '@/api/request'
import download from '@/api/download'

// 增改共用
export function submit(data) {
  return request({
      url: '/market-service/epc/balance/fundForecast/submit',
      method: 'post',
      data
  })
}

export function fetchList(params) {
  return request({
      url: `/market-service/epc/balance/fundForecast/list`,
      params
  })
}

// 下载模版
export function downloadTemplate() {
  return download({
      url: '/market-service/epc/balance/fundForecast/export/template',
  })
}
// 导出
export function exportFile(params) {
  return download({
      url: `/market-service/epc/balance/fundForecast/export/${params.id}`,
  })
}

