<template>
  <div class="chart-container">
    <div class="card-money-container">
      <div class="unit">单位：元</div>

      <div class="card-money">
        <div class="card-money-item" v-for="item in moneyList" :key="item.key">
          <div class="title" :style="{ backgroundColor: item.color }">{{ item.name }}</div>
          <div class="value">{{ item.value ? item.value.toLocaleString() : item.value }}</div>
        </div>
      </div>
    </div>

    <div style id="progress">
      <div class="title">本年分包支付比例</div>
      <a-progress :percent="ratio" style="margin-bottom: 10px" />
      <div class="flex align-center">
        <span class="square1"></span>
        <span>
          本年回款额：{{
          value.annualPayedAmt
          ? value.annualPayedAmt.toLocaleString()
          : value.annualPayedAmt
          }}元
        </span>
      </div>
      <div class="flex align-center">
        <span class="square2"></span>
        当日余额：{{
        value.remainAmt
        ? value.remainAmt.toLocaleString()
        : value.remainAmt
        }}元
      </div>
    </div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    value(val) {
      for (const key in val) {
        let temp = this.moneyList.find(item => key === item.key);
        if (temp) {
          temp.value = val[key] || 0;
        }
      }
    }
  },
  computed: {
    ratio() {
      return formatRatio(
        this.value.annualPayedAmt,
        this.$calc.expr(`${this.value.annualPayedAmt}+${this.value.remainAmt}`)
      );
    }
  },
  data() {
    return {
      moneyList: [
        {
          key: "totalSubContractAmt",
          name: "分包合同累计金额",
          value: 0,
          color: "#4c6bdd"
        },
        {
          key: "totalInvoiceAmt",
          name: "分包累计收票金额",
          value: 0,
          color: "#5abda0"
        },
        {
          key: "totalPayRatio",
          name: "分包累计支付比例",
          value: 0,
          color: "#710f6"
        },
        {
          key: "totalPayedAmt",
          name: "分包累计支付金额",
          value: 0,
          color: "#5a92d9"
        },
        {
          key: "bookBalance",
          name: "账面余额",
          value: 0,
          color: "rgb(221,161,52)"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.chart-container {
  width: 100%;
  background-color: rgb(250, 250, 250);
  padding: 20px;
}

.card-money-container {
  .unit {
    text-align: end;
  }

  .card-money {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0 20px;

    .card-money-item {
      width: 45%;
      background-color: #fff;
      border: 5px solid #fff;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 5px;

      .title {
        background-color: #1890ff;
        color: #fff;
        padding: 5px 10px;
        text-align: center;
        border-radius: 5px;
      }

      .value {
        text-align: center;
        font-size: 20px;
        padding: 10px;
      }
    }
  }
}

#progress {
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 30px 20px 20px;

  .title {
    font-size: 15px;
  }

  .square1,
  .square2 {
    width: 10px;
    height: 10px;
    background-color: #1890ff;
    margin-right: 10px;
  }

  .square2 {
    background-color: #f5f5f5;
  }
}
</style>
