<template>
  <div class="flex">
    <div class="flex-1 content">
      <a class="danger" href="#" v-if="removeVisible" @click.prevent="remove">
        <a-icon type="delete" />
      </a>
      {{ detail.name}}(元)
    </div>

    <div class="flex-1 content">
      <a-input-number
        style="width: 100%"
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.contractAmt"
        @change="(val) => onChange('contractAmt', val)"
      />
    </div>

    <div class="flex-1 flex flex-col">
      <div class="flex-1 content">
        开票
        <a-icon
          type="right-circle"
          class="icon-btn"
          style="color: #1890ff"
          @click="$emit('select', detail.name)"
        />
      </div>
      <div class="flex-1 content">回款</div>
    </div>

    <div class="flex-1">
      <div class="content">金额(元)</div>
      <div class="content">比例(%)</div>
      <div class="content">金额(元)</div>
      <div class="content">比例(%)</div>
    </div>

    <div class="flex-1">
      <!-- 开票金额 -->
      <div class="content">{{ detail.invoiceAmt ? $numberFormat(detail. invoiceAmt) : 0 }}</div>
      <!-- 开票比例 -->
      <div class="content">{{ ratio.invoiceR }}</div>
      <div class="value">
        <!-- 回款金额 -->
        <a-input-number
          style="width: 100%"
          :formatter="$inputNumberFormat"
          placeholder="请输入"
          :value="detail.payedAmt"
          @change="(val) => onChange('payedAmt', val)"
        />
      </div>
      <div class="content">
        <!-- 回款比例 -->
        {{ ratio.payedR }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    total() {
      return typeof this.detail.contractAmt === "number"
        ? this.detail.contractAmt
        : 0;
    },

    ratio() {
      return {
        invoiceR: formatRatio(this.detail.invoiceAmt, this.total),
        payedR: formatRatio(this.detail.payedAmt, this.total)
      };
    },

    removeVisible() {
      return !["设计类", "建安类", "设备类", "其他类"].includes(
        this.detail.name
      );
    }
  },

  methods: {
    onChange(key, value) {
      const index = this.list.findIndex(item => item.key === this.detail.key);
      this.list.splice(index, 1, {
        ...this.detail,
        [key]: value
      });
      // this.$emit("change", this.list);
    },
    remove() {
      const index = this.list.findIndex(item => item.key === this.detail.key);

      const that = this;
      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          that.list.splice(index, 1, {
            ...that.detail,
            delStatus: 1
          });
          // that.$emit("change", that.list);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px -1px -1px 0px;
  position: relative;

  .danger {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.value {
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px -1px -1px 0px;
  padding: 1px;
}
.icon-btn {
  cursor: pointer;
  margin-left: 5px;
}
</style>
