<template>
  <div id="package-pay">
    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" :colon="false">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="当前项目" class="default-highlight" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同编号" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input v-decorator="[
                            'contractCode',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]">
              <ContractSelector slot="addonAfter" @change="onSelectContract">
                <a-button type="primary"> 获取合同 </a-button>
              </ContractSelector>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="工程名称" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="合同名称">
            <a-input v-decorator="[
                            'contractName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="分包方" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input v-decorator="[
                            'contractor',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同额(元)" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input-number :formatter="$inputNumberFormat" style="width: 100%" v-decorator="[
                            'contractAmt',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]" @change="contractAmtChange"></a-input-number>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="付款类型">
            <a-radio-group v-decorator="[
                            'payType',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！',
                                },
                              ],
                            },
                          ]">
              <a-radio :value="item.value" v-for="item in payTypeList" :key="item.value">
                {{ item.name }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24" class="table">
          <SubPackageTable title="总承包项目分包付款表" :defaultData="list" :sumObj="sumObj" :id="id" @totalChange="totalChange"
            @change="getSubPayList" />
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
            <span slot="label" class="">附件</span>
            <div class="link-list" v-decorator="[
                            'file',
                            { rules: [{ required: true, message: '请上传附件' }] },
                          ]">
              <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
                <div class="link" v-for="item in fileList" :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank" download :href="item.completePath">{{
                                          item.name
                                          }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control" @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button @click="addDraft" v-if="!isView">存草稿</a-button>
          <a-button htmlType="submit" type="primary" v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import ContractSelector from "@/components/contract-selector";
import SubPackageTable from "./components/SubPackageTable";
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetail,
  fetchDetailByPid,
  exportFile,
  downloadTemplate,
} from "@/api/epc/income-expense/subContractPay.js";
import { formatRatio } from "@/utils/formatRatio.js";

import { mapGetters } from "vuex";

export default {
  components: {
    ContractSelector,
    SubPackageTable,
    FileUpload,
  },
  props: {
    msg: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      stage: "epc_balance_subpack_pay",

      form: this.$form.createForm(this),
      pid: "",

      id: "", //表的id
      hid: "",
      list: [], // 表格初始数据
      origList: [],
      contractAmt: 0,
      oldpaidAmt: 0, //历史累计已付金额
      sumObj: {
        paidRatio: 0,
        totalPaidAmt: 0,
        totalPaidRatio: 0,
      },
      paidAmt: 0, //本次已付金额

      isView: false,

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict", "findSingleDictName"]),

    payTypeList() {
      return this.findDataDict("epc_pay_type");
    },
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.hid = hid || "";
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    } else {
      // 获取历史累计已付金额
      this.getDetailByPid();
    }
  },

  methods: {
    getDetailByPid() {
      fetchDetailByPid({ id: this.pid }).then((res) => {
        if (res) {
          this.form.setFieldsValue({
            contractCode: res.contractCode,
            contractName: res.contractName,
            contractor: res.contractor,
            contractAmt: res.contractAmt,
          });
          this.contractAmt = res.contractAmt;

          this.oldpaidAmt = res.totalPaidAmt || 0; //记录历史累计金额
          let totalPaidRatio = formatRatio(res.totalPaidAmt, res.contractAmt);
          this.sumObj = {
            totalPaidAmt: res.totalPaidAmt || 0,
            totalPaidRatio,
          };
        }
      });
    },
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;
          this.setData(res);
        })
        .catch();
    },
    setData(res) {
      this.form.setFieldsValue({
        contractCode: res.contractCode,
        contractName: res.contractName,
        contractor: res.contractor,
        contractAmt: res.contractAmt,
        payType: res.payType,
      });

      this.sumObj = {
        paidRatio: res.paidRatio || 0,
        totalPaidAmt: res.totalPaidAmt || 0,
        totalPaidRatio: res.totalPaidRatio || 0,
      };
      this.contractAmt = res.contractAmt || null;

      if (this.id) {
        //编辑时，重新计算历史累计金额
        let paidAmt = res.subPayList?.reduce((pre, cur) => {
          return pre + cur["paidAmt"];
        }, 0);

        this.oldpaidAmt = res.totalPaidAmt - paidAmt || 0;
      }

      if (res.subPayList) {
        console.log(res.subPayList);
        this.list = res.subPayList;
        this.origList = [...this.list];
      }

      if (res.attachments) {
        this.form.setFieldsValue({
          file: true,
        });
        this.fileList = res.attachments;
      }
    },

    totalChange(paidAmt) {
      //paidAmt 本次已付金额
      this.paidAmt = paidAmt;

      this.sumObj = {
        paidRatio: formatRatio(paidAmt, this.contractAmt), //本次实付百分比
      };

      let newTotalPaidAmt = paidAmt + this.oldpaidAmt; //计算新的累计已付金额（oldpaidAmt：历史累计和）
      this.sumObj = {
        ...this.sumObj,
        totalPaidAmt: newTotalPaidAmt, //累计已付
      };
      this.sumObj = {
        ...this.sumObj,
        totalPaidRatio: formatRatio(newTotalPaidAmt, this.contractAmt),
      };
    },
    contractAmtChange(val) {
      this.contractAmt = val;
      this.totalChange(this.paidAmt);
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    getSubPayList(value) {
      this.list = value;
    },

    addDraft() {
      this.$emit("addDraft");
    },

    saveDraft() {
      // let content = { name: "1" };
      this.form.validateFields((err, values) => {
        // 如果没有id ，则新增
        delete values.file;
        let content = {
          ...values,
          pid: this.msg.pid,
          projectName: this.msg.projectName,
          designCode: this.msg.designCode,
          attachments: this.fileList,
          subPayList: this.list.map((item) => {
            return {
              itemName: item.itemName,
              payableAmt: item.payableAmt,
              sctAmt: item.sctAmt,
              otherAmt: item.otherAmt,
              prePayAmt: item.prePayAmt,
              watEleAmt: item.watEleAmt,
              grtAmt: item.grtAmt,
              paidAmt: item.paidAmt,
              sum:
                item.payableAmt +
                item.sctAmt +
                item.otherAmt +
                item.prePayAmt +
                item.watEleAmt +
                item.grtAmt +
                item.paidAmt,
            };
          }),
        };
        this.$emit("updateDraft", content);
      });
    },

    onSelectDraft(content) {
      console.log(content);
      this.setData(content);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              subPayList: this.list.map((item) => {
                return {
                  itemName: item.itemName,
                  payableAmt: item.payableAmt,
                  sctAmt: item.sctAmt,
                  otherAmt: item.otherAmt,
                  prePayAmt: item.prePayAmt,
                  watEleAmt: item.watEleAmt,
                  grtAmt: item.grtAmt,
                  paidAmt: item.paidAmt,
                };
              }),
            }).then(() => {
              this.list = [];
              this.fileList = [];
              this.sumObj = {};
              this.contractAmt = 0;
              this.getDetailByPid();
            });
          } else {
            // 找到删除的元素
            this.origList.forEach((item) => {
              let index = this.list.findIndex((ele) => ele.id === item.id);
              if (index < 0) {
                item.delStatus = 1;
              }
            });
            this.list.push(
              ...this.origList.filter((item) => item.delStatus === 1)
            );

            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              subPayList: this.list.map((item) => {
                return {
                  itemName: item.itemName,
                  payableAmt: item.payableAmt,
                  sctAmt: item.sctAmt,
                  otherAmt: item.otherAmt,
                  prePayAmt: item.prePayAmt,
                  watEleAmt: item.watEleAmt,
                  grtAmt: item.grtAmt,
                  paidAmt: item.paidAmt,
                };
              }),
            }).then(() => {
              this.getDetail();
            });
          }
        }
      });
    },

    onSelectContract(value) {
      console.log(value.id);
      this.form.setFieldsValue({
        contractCode: value.code,
        contractName: value.name,
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-bottom: 16px;
}

.center {
  margin-top: 80px;
}
</style>
<style lang="less">
#package-pay {
  .ant-table-wrapper {
    width: 911px;
  }

  .ant-input-group-addon {
    padding: 0;
  }
}
</style>
