<template>
  <div style="margin-top: 16px">
    <a-button type="primary" @click="add">新增</a-button>

    <a-modal :visible="visible" title="新增合同金额明细" @cancel="cancel" :footer="null">
      <a-input placeholder="请输入名称" v-model="name" />
      <div class="right" style="margin-top: 16px">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="save">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      name: ""
    };
  },
  methods: {
    add() {
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },
    save() {
      if (!this.name) {
        this.$message.error("请输入名称");
        return;
      }
      this.$emit("change", this.name);
      this.cancel();
      this.name = "";
    }
  }
};
</script>