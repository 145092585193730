import request from "../request";

export function fetchDetail(data) {
  return request({
    url: "/market-service/epc/common/draft/query",
    method: "post",
    data,
  });
}

export function add(data) {
  return request({
    url: "/market-service/epc/common/draft/add",
    method: "post",
    data,
  });
}

export function remove(data) {
  return request({
    url: "/market-service/epc/common/draft/delete",
    method: "post",
    data,
  });
}
