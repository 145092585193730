<template>
  <div class="panel contract-account-container">
    <div class="label title">总承包项目收支台账</div>
    <div class="required label">合同编号</div>
    <div class="value1">
      <a-input
        placeholder="请输入"
        :value="detail.contractCode"
        @change="e => onChange('contractCode', e.target.value)"
      />
    </div>
    <div class="required label">合同名称</div>
    <div class="value2">
      <a-input
        placeholder="请输入"
        :value="detail.contractName"
        @change="e => onChange('contractName', e.target.value)"
      />
    </div>
    <div class="required label">业主单位</div>
    <div class="value3">
      <a-textarea
        :auto-size="{ minRows: 1 }"
        placeholder="请输入"
        :value="detail.org"
        @change="e => onChange('org', e.target.value)"
      />
    </div>
    <div class="required label">付款条件</div>
    <div class="value3">
      <a-textarea
        :auto-size="{ minRows: 1 }"
        placeholder="请输入"
        :value="detail.payCondition"
        @change="e => onChange('payCondition', e.target.value)"
      />
    </div>
    <div class="required label">合同金额(元)</div>
    <div class="value1">
      {{ contractAmt ? contractAmt.toLocaleString() : '--' }}
      <!-- <a-input-number
        style="width: 100%"
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.contractAmt"
        @change="(value) => onChange('contractAmt', value)"
      />-->
    </div>
    <div class="required label">结算审计金额(元)</div>
    <div class="value2">
      <a-input-number
        style="width: 100%"
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.receivedRatio"
        @change="(value) => onChange('receivedRatio', value)"
      />
    </div>
    <div class="required label">累计开票金额(元)</div>
    <div class="value1">
      {{ sumInvoiceAmt ? sumInvoiceAmt.toLocaleString() : '--' }}
      <!-- <a-input-number
        style="width: 100%"
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.sumInvoiceAmt"
        @change="(value) => onChange('sumInvoiceAmt', value)"
      />-->
    </div>
    <div class="required label">累计回款金额(元)</div>
    <div class="value2">
      {{ sumPayedAmt ? sumPayedAmt.toLocaleString() : '--' }}
      <!-- <a-input-number
        style="width: 100%"
        :formatter="$inputNumberFormat"
        placeholder="请输入"
        :value="detail.sumPayedAmt"
        @change="(value) => onChange('sumPayedAmt', value)"
      />-->
    </div>

    <div class="label">累计开票比例(%)</div>
    <div class="value1">{{ ratio.sumInvoiceR }}</div>
    <div class="label">累计回款比例(%)</div>
    <div class="value2">{{ ratio.sumPayR }}</div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js"; //计算比例并格式化为2位小数
import accurate from "accurate";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    amtCateList: {
      type: Array,
      default: () => []
    }
  },
  // watch: {
  //   contractCode(newV) {
  //     this.form.setFieldsValue({
  //       contractCode: newV,
  //     });
  //   },
  //   contractName(newV) {
  //     this.form.setFieldsValue({
  //       contractName: newV,
  //     });
  //   },
  // },
  computed: {
    ratio() {
      //计算累计回款比例(%)，累计开票比例(%)，
      return {
        sumInvoiceR: formatRatio(this.sumInvoiceAmt, this.contractAmt),
        sumPayR: formatRatio(this.sumPayedAmt, this.contractAmt)
      };
    },
    // 合同金额
    contractAmt() {
      if (Array.isArray(this.amtCateList) && this.amtCateList.length) {
        let num = 0;

        this.amtCateList
          .filter(item => item.delStatus !== 1)
          .forEach(item => {
            if (
              typeof item.contractAmt === "number" &&
              !isNaN(item.contractAmt)
            ) {
              num = accurate.add(num, item.contractAmt);
            }
          });
        return num > 0 ? num : 0;
      } else {
        return 0;
      }
    },
    // 累计开票金额
    sumInvoiceAmt() {
      if (Array.isArray(this.amtCateList) && this.amtCateList.length) {
        let num = 0;
        this.amtCateList
          .filter(item => item.delStatus !== 1)
          .forEach(item => {
            if (
              typeof item.invoiceAmt === "number" &&
              !isNaN(item.invoiceAmt)
            ) {
              num = accurate.add(num, item.invoiceAmt);
            }
          });
        return num > 0 ? num : 0;
      } else {
        return 0;
      }
    },
    // 累计回款金额
    sumPayedAmt() {
      if (Array.isArray(this.amtCateList) && this.amtCateList.length) {
        let num = 0;
        this.amtCateList
          .filter(item => item.delStatus !== 1)
          .forEach(item => {
            if (typeof item.payedAmt === "number" && !isNaN(item.payedAmt)) {
              num = accurate.add(num, item.payedAmt);
            }
          });
        return num > 0 ? num : 0;
      } else {
        return 0;
      }
    }
  },

  methods: {
    onChange(key, value) {
      this.$emit("change", {
        ...this.detail,
        [key]: value
      });
    }
  }
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px 3px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .title2 {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .title3 {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
    padding: 0;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    padding: 0;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    padding: 0;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
<style lang="less">
.contract-account-container {
  .ant-input {
    border-width: 0;
  }
}
</style>
