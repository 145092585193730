import { render, staticRenderFns } from "./subpackage-detail.vue?vue&type=template&id=aa227c92&scoped=true"
import script from "./subpackage-detail.vue?vue&type=script&lang=js"
export * from "./subpackage-detail.vue?vue&type=script&lang=js"
import style0 from "./subpackage-detail.vue?vue&type=style&index=0&id=aa227c92&prod&lang=less"
import style1 from "./subpackage-detail.vue?vue&type=style&index=1&id=aa227c92&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa227c92",
  null
  
)

export default component.exports