// 分包付款
import request from '@/api/request'
import download from '@/api/download'

export function add(data) {
  return request({
      url: '/market-service/epc/balance/expenseApp/add',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/epc/balance/expenseApp/update',
      method: 'post',
      data
  })
}

export function fetchDetail(data) {
  return request({
      url: `/market-service/epc/balance/expenseApp/query/${data.id}`,
      data
  })
}

// 下载模版
export function downloadTemplate() {
  return download({
      url: '/market-service/epc/balance/expenseApp/export/template',
  })
}
// 导出
export function exportFile(params) {
  return download({
      url: `/market-service/epc/balance/expenseApp/export/${params.id}`,
  })
}

