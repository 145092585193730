<template>
  <div>
    <Pane :bottom="0" :showRemarks="false" title="资金收支">
      <a-tabs
        v-model="activeKey"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <!-- 从文件列表打开，只能展示当前表单 -->
        <a-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :tab="item.name"
          :disabled="item.disabled"
        ></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-space>
          <Draft
            v-if="activeKey === '2' || activeKey === '0'"
            @select="onSelectDraft"
            :pid="pid"
            :stage="stage"
            ref="draftRef"
          />
          <div class="export-download">
            <div
              @click="visible = true"
              class="progress"
              v-if="!processDisable"
            >
              <img src="@/assets/epc/progress.jpg" alt="" />
              <a>流程查看</a>
            </div>
          </div>
        </a-space>
      </template>

      <a-modal
        :visible="draftVisible"
        title="保存草稿"
        @ok="saveDraft"
        @cancel="cancelDraft"
      >
        <div style="padding: 8px 0">
          <a-input placeholder="请输入草稿标题" v-model="draftTitle" />
        </div>
      </a-modal>

      <a-modal
        :visible="visible"
        title="流程图"
        :dialogClass="activeKey === '3' ? 'capitalModal' : 'modal'"
        cancelText="关闭"
        :footer="null"
        @cancel="visible = false"
      >
        <img
          :src="url"
          :style="{ width: activeKey === ('0' || '2') ? '70%' : '100%' }"
        />
      </a-modal>
    </Pane>

    <div class="container">
      <IncomeExpenseAccount
        v-if="activeKey === '0' && hasRight"
        ref="incExpAcc"
        :msg="pData"
        @addDraft="addDraft"
        @updateDraft="toUpdateDraft"
      ></IncomeExpenseAccount>
      <CapitalPredict
        v-if="activeKey === '1'"
        ref="capPre"
        :msg="pData"
        @addDraft="addDraft"
      ></CapitalPredict>
      <SubpackagePay
        v-if="activeKey === '2'"
        ref="subPay"
        :msg="pData"
        @addDraft="addDraft"
        @updateDraft="toUpdateDraft"
      ></SubpackagePay>
      <SubpackagePayApproval
        v-if="activeKey === '3'"
        ref="subPayApp"
        :msg="pData"
        @addDraft="addDraft"
      ></SubpackagePayApproval>
    </div>
  </div>
</template>

<script>
import draft from "@/views/epc/mixins/draft";
import { fetchDetail } from "@/api/epc";
import { fetchDetail as fetchIEDetail } from "@/api/epc/income-expense/account";

import IncomeExpenseAccount from "./components/income-expense-account";
import CapitalPredict from "./components/capital-predict";
import SubpackagePay from "./components/subpackage-pay";
import SubpackagePayApproval from "./components/subpackage-pay-approval";
import moment from "moment";

export default {
  name: "incExpCap",
  mixins: [draft],
  components: {
    IncomeExpenseAccount,
    CapitalPredict,
    SubpackagePay,
    SubpackagePayApproval,
  },
  data() {
    return {
      url: require("@/assets/epc/approvalFlow/5-1account.png"),
      visible: false,
      processDisable: false,

      activeKey: "0",
      hasRight: false, //判断是否有权限
      tabList: [
        { key: "0", name: "收支台账", disabled: false },
        { key: "1", name: "资金预测表", disabled: false },
        { key: "2", name: "分包付款审核表", disabled: false },
        { key: "3", name: "分包支出申请", disabled: false },
      ],

      pData: {},
      pid: "",
      stage: "epc_balance_fund_forecast",
      id: "",
      isView: false,

      fileList: [],
      selectedFileList: [],
    };
  },
  watch: {
    activeKey: {
      handler(newV, oldVal) {
        if (newV === "0") {
          //若点击收支台账，查询是否有权限
          this.setUnableTab(oldVal);
          this.processDisable = false;
          this.url = require("@/assets/epc/approvalFlow/5-1account.png");

          this.stage = "epc_balance_contract";
        } else if (newV === "2") {
          this.processDisable = false;
          this.url = require("@/assets/epc/approvalFlow/5-2package.png");

          this.stage = "epc_balance_subpack_pay";
        } else if (newV === "3") {
          this.processDisable = false;
          this.url = require("@/assets/epc/approvalFlow/5-3pay.png");

          this.stage = "epc_balance_subpack_expense_app";
        } else {
          this.processDisable = true;

          this.stage = "epc_balance_fund_forecast";
        }
      },
      immediate: true,
    },
    pid() {
      if (this.activeKey === "0") {
        this.setUnableTab();
      }
    },
  },
  mounted() {
    const { activeKey, pid, id, isView } = this.$route.query;
    this.pid = pid || "";
    this.activeKey = activeKey || "";
    this.id = id || "";
    this.isView = Boolean(isView) || false;

    this.getName(pid);

    if (id) {
      // 设置禁用tab
      this.setTab();
    }
  },
  methods: {
    moment,
    getName(pid) {
      fetchDetail(pid)
        .then((res) => {
          this.pData = {
            projectName: res.name,
            designCode: res.designCode,
            pid: pid,
          };
        })
        .catch();
    },
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },

    onSelectDraft(draft) {
      console.log(draft);
      if (draft.id && draft.content) {
        this.draftId = draft.id; // 暂存使用的草稿 id
        this.draftTitle =
          typeof draft.title === "string" ? draft.title.split(" @")[0] : "";

        const content = JSON.parse(draft.content);
        console.log(content);
        switch (this.activeKey) {
          case "0":
            this.$refs.incExpAcc.onSelectDraft(content);
            break;
          case "2":
            this.$refs.subPay.onSelectDraft(content);
            break;
          case "3":
            this.$refs.subPayApp.onSelectDraft(content);
            break;
        }
      }
    },

    saveDraft() {
      switch (this.activeKey) {
        case "0":
          this.$refs.incExpAcc.saveDraft();
          break;
        case "1":
          this.$refs.capPre.saveDraft();
          break;
        case "2":
          this.$refs.subPay.saveDraft();
          break;
        case "3":
          this.$refs.subPayApp.saveDraft();
          break;
      }
    },

    toUpdateDraft(val) {
      console.log(val);
      this.updateDraft({
        pid: this.pid,
        stage: this.stage,
        title: this.draftTitle,
        type: this.draftType,
        content: JSON.stringify(val),
      }).then(() => {
        this.draftVisible = false;
        this.draftTitle = "";
      });
    },

    download() {
      switch (this.activeKey) {
        case "0":
          this.$refs.incExpAcc.download();
          break;
        case "1":
          this.$refs.capPre.download();
          break;
        case "2":
          this.$refs.subPay.download();
          break;
        case "3":
          this.$refs.subPayApp.download();
          break;
      }
    },
    exportForm() {
      switch (this.activeKey) {
        case "0":
          this.$refs.incExpAcc.exportForm();
          break;
        case "1":
          this.$refs.capPre.exportForm();
          break;
        case "2":
          this.$refs.subPay.exportForm();
          break;
        case "3":
          this.$refs.subPayApp.exportForm();
          break;
      }
    },

    setUnableTab(oldVal) {
      console.log(this.pid);
      if (!this.pid) {
        return;
      }
      fetchIEDetail({ pid: this.pid }).then((res) => {
        if (res && res.code === 400) {
          // 禁用收支台账，tab跳为上个tab
          this.activeKey = oldVal;
        } else {
          this.hasRight = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>

<style lang="less">
.capitalModal {
  width: 800px !important;
}
.modal {
  text-align: center;
}
</style>
