var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.control === 'add' ? '开票' : '修改发票',"footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"发票号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {
          initialValue: _vm.detail.code,
        }]),expression:"['code', {\n          initialValue: detail.code,\n        }]"}]})],1),_c('a-form-item',{attrs:{"label":"发票类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['type', {
          initialValue: _vm.detail.type,
        }]),expression:"['type', {\n          initialValue: detail.type,\n        }]"}],attrs:{"button-style":"solid"}},_vm._l((_vm.invoiceTypeList),function(item){return _c('a-radio-button',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"开票日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date', {
          initialValue: _vm.detail.date ? _vm.moment(  _vm.detail.date) : undefined,
        }]),expression:"['date', {\n          initialValue: detail.date ? moment(  detail.date) : undefined,\n        }]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"税点"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['ratio', {
          initialValue: _vm.detail.ratio
        }]),expression:"['ratio', {\n          initialValue: detail.ratio\n        }]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100,"formatter":_vm.$ratioFormatter}})],1),_c('a-form-item',{attrs:{"label":"合计金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'amount',
          { 
              initialValue: _vm.detail.amount,
              rules: [{ required: true, message: '请输入！' }] },
        ]),expression:"[\n          'amount',\n          { \n              initialValue: detail.amount,\n              rules: [{ required: true, message: '请输入！' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary","icon":"upload"}},[_vm._v("上传附件")])],1),_c('div',{staticClass:"link-list"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"link"},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"control",on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])}),0)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }