import Draft from "@/views/epc/components/draft";
import { add } from "@/api/epc/draft.js";
export default {
  components: {
    Draft,
  },
  data() {
    return {
      draftVisible: false,
      draftTitle: "",
      draftId: "",
    };
  },

  methods: {
    addDraft() {
      this.draftVisible = true;
    },
    cancelDraft() {
      this.draftVisible = false;
    },
    async updateDraft(params) {
      await add(params);
      this.$refs?.draftRef?.getList();
    },
  },
};
