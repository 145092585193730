<template>
  <div id="income-expense-account">
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item
            label="当前项目"
            class="default-highlight"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
          >
            <a-input :disabled="true" :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="合同编号" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
            <a-input
              v-decorator="[
              'contractCode',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]"
            >
              <ContractSelector slot="addonAfter" @change="onSelectContract">
                <a-button type="primary">获取合同</a-button>
              </ContractSelector>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="工程名称" class="default-highlight">
            <a-input :disabled="true" :placeholder="msg.projectName"></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="file-title">
        <div class="line"></div>
        <div class="title">合同详情</div>
      </div>

      <div class="content">
        <div class="left-part">
          <ContractAccount
            style="width: 100%"
            :detail="contractInfoObj"
            @change="contractInfoChange"
            :amtCateList="amtCateList"
          ></ContractAccount>
        </div>

        <div class="chart">
          <ContractAccountChart
            :sumInvoiceAmt="contractInfoObj.sumInvoiceAmt"
            :sumPayedAmt="contractInfoObj.sumPayedAmt"
          ></ContractAccountChart>
        </div>
      </div>

      <div class="file-title">
        <div class="line"></div>
        <div class="title">合同金额明细</div>
      </div>

      <div class="content">
        <div class="left-part">
          <AssetaTable
            v-for="(item) in amtCateList.filter(item => item.delStatus !== 1)"
            :key="item.key"
            :list="amtCateList"
            :detail="item"
            @select="payload => selectedName = payload"
          ></AssetaTable>
          <ContractAssetsAdd @change="onAddContract" />
          <Invoice
            :list="invoiceList"
            :selectedName="selectedName"
            @select="payload => selectedName = payload"
            :amtCateList="amtCateList"
            @change="payload => invoiceList = payload"
          />
        </div>

        <div class="chart" style="height: 200px">
          <ContractAssetsChart :list="amtCateList.filter(item => item.delStatus !== 1)"></ContractAssetsChart>
        </div>
      </div>

      <div class="file-title">
        <div class="line"></div>
        <div class="title">合同分包明细</div>
      </div>

      <div class="content">
        <a-tabs v-model="activeKey" class="left-part">
          <a-tab-pane :tab="item" v-for="(item,index) in invoiceTypeList" :key="index">
            <SubpackageDetail
              v-for="(item) in filteredSubList"
              :key="item.key"
              :detail="item"
              :list="subContractList"
            ></SubpackageDetail>

            <div style="margin-top: 16px">
              <a-button type="primary" @click="addTable">新增</a-button>
            </div>
          </a-tab-pane>
        </a-tabs>

        <div class="right-part">
          <SubpackageDetailChart :value="subpackageDetail"></SubpackageDetailChart>
        </div>
      </div>

      <div class="center">
        <a-space>
          <a-button @click="addDraft" v-if="!isView">存草稿</a-button>
          <a-button htmlType="submit" type="primary" v-if="!isView" :loading="loading">提交</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import ContractAccount from "./components/contract-account.vue";
import ContractAccountChart from "./components/contract-account-chart.vue";

import AssetaTable from "./components/assets-table.vue";
import ContractAssetsAdd from "./components/contract-assets-add.vue";
import Invoice from "./components/components/Invoice.vue";
import ContractAssetsChart from "./components/contract-assets-chart.vue";

import SubpackageDetail from "./components/subpackage-detail.vue";
import SubpackageDetailChart from "./components/subpackage-detail-chart.vue";

import ContractSelector from "@/components/contract-selector";

import {
  submit,
  fetchDetail as getDetailByPid
} from "@/api/epc/income-expense/account.js";

import accurate from "accurate";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ContractAccount,
    ContractAccountChart,

    AssetaTable,
    ContractAssetsAdd,
    Invoice,
    ContractAssetsChart,

    SubpackageDetail,
    SubpackageDetailChart,

    ContractSelector
  },

  data() {
    return {
      form: this.$form.createForm(this),
      pid: "",
      hid: "",
      id: "", //合同id

      contractInfoObj: {
        contractCode: "",
        contractName: "",
        org: "",
        payCondition: "",
        contractAmt: null,
        receivedRatio: null,
        sumInvoiceAmt: null,
        sumPayedAmt: null
      }, //合同详情数据

      amtCateList: [
        {
          key: "1",
          name: "设计类",
          delStatus: 0,
          sort: 1
        },
        {
          key: "2",
          name: "建安类",
          delStatus: 0,
          sort: 2
        },
        {
          key: "3",
          name: "设备类",
          delStatus: 0,
          sort: 3
        },
        {
          key: "4",
          name: "其他类",
          delStatus: 0,
          sort: 4
        }
      ], // 合同金额明细

      activeKey: 0, //分包明细tab值
      subContractList: [], // 分包列表
      subpackageDetail: {}, //分包明细累计数据，合同累计金额，合同收票、支付金额、支付比例、账面余额

      invoiceList: [], // 发票列表
      selectedName: "", // 看谁的发票

      isView: false,

      loading: false
    };
  },

  watch: {
    "contractInfoObj.sumPayedAmt"() {
      console.log("累计回款金额变化，计算账面余额");
      this.calcBookBalance();
    },
    amtCateList: {
      handler() {
        console.log("设计费的回款金额变化，计算账面余额");
        this.calcBookBalance();
      },
      deep: true
    },
    subContractList: {
      handler() {
        console.log("分包列表变化，计算账面余额");
        this.calcBookBalance();
      },
      deep: true
    }
  },
  computed: {
    invoiceTypeList() {
      return this.amtCateList
        .map(item => item.name)
        .filter((item, index, self) => self.indexOf(item) === index);
    },

    filteredSubList() {
      return this.subContractList.filter(
        item =>
          item.type === this.invoiceTypeList[this.activeKey] &&
          item.delStatus !== 1
      );
    }
  },

  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.hid = hid || "";
    this.isView = Boolean(isView) || false;

    if (this.pid) {
      this.getDetail();
    }
  },

  methods: {
    onSelectContract(value) {
      this.form.setFieldsValue({
        contractCode: value.code
      });

      this.contractInfoObj.contractCode = value.code;
      this.contractInfoObj.contractName = value.name;
      this.contractInfoObj.org = value.partyAname;
      this.$message.success("已修改合同");
    },

    getDetail() {
      getDetailByPid({
        pid: this.pid
      })
        .then(res => {
          if (res) {
            this.setData(res);
          }
        })
        .catch();
    },
    setData(res) {
      this.id = this.id || res.id; //若有id，则为当前id
      this.hid = this.hid || res.historyId || ""; //若有历史id，则为当前历史id

      this.form.setFieldsValue({
        contractCode: res.contractCode
      });

      //合同信息
      this.contractInfoObj.contractCode = res.contractCode;
      this.contractInfoObj.contractName = res.contractName;
      this.contractInfoObj.org = res.org;
      this.contractInfoObj.payCondition = res.payCondition;
      this.contractInfoObj.contractAmt = res.contractAmt;
      this.contractInfoObj.receivedRatio = res.receivedRatio;
      this.contractInfoObj.sumInvoiceAmt = res.sumInvoiceAmt;
      this.contractInfoObj.sumPayedAmt = res.sumPayedAmt;

      // 合同金额明细
      if (Array.isArray(res.amtCateList)) {
        this.amtCateList = res.amtCateList.map(item => {
          return {
            ...item,
            key: item.id // 新增时允许自定义key
          };
        });
      }

      if (Array.isArray(res.invoiceList)) {
        this.invoiceList = res.invoiceList.map(item => {
          return {
            ...item,
            attachments: item.attachmentList,
            key: item.id
          };
        });
      }

      // 分包信息
      if (Array.isArray(res.subContractList)) {
        this.subContractList = res.subContractList.map(item => {
          return {
            ...item,
            // payRatio: formatRatio(item.sumPayedAmt, item.contractAmt),
            key: item.id
          };
        });
      }

      // 分包信息echarts图
      this.subpackageDetail = {
        remainAmt: res.remainAmt,
        annualPayedAmt: res.annualPayedAmt,
        totalSubContractAmt: res.totalSubContractAmt,
        totalInvoiceAmt: res.totalInvoiceAmt,
        totalPayRatio: res.totalPayRatio,
        totalPayedAmt: res.totalPayedAmt
      };

      this.calcBookBalance();
    },

    // 修改合同数据
    contractInfoChange(payload) {
      this.contractInfoObj = payload;
      this.calcBookBalance();
    },
    // 新增合同金额明细
    onAddContract(name) {
      if (this.amtCateList.filter(item => item.name === name).length > 0) {
        this.$message.error("请勿重复命名");
        return;
      }

      this.amtCateList.push({
        key: Date.now().toString() + Math.random(),
        name,
        delStatus: 0,
        sort: this.amtCateList.length
      });
    },

    // 增加分包明细表单
    addTable() {
      this.subContractList.push({
        projectId: this.msg.pid,
        code: "",
        name: "",
        org: "",
        payCondition: "",
        contractAmt: null,
        sumPayedAmt: null,
        sumInvoiceAmt: null,
        amtDue: null,

        delStatus: 0,

        key: Date.now().toString() + Math.random(),
        type: this.invoiceTypeList[this.activeKey]
      });
    },

    // 计算账面余额
    calcBookBalance() {
      let num = 0;
      this.subContractList.forEach(item => {
        num = this.$calc.expr(`${num}+${item.sumPayedAmt || 0}`);
      });

      // 找到设计类的合同
      let designPayedAmt = 0;
      const design = this.amtCateList.find(item => item.name === "设计类");
      if (design) {
        designPayedAmt = design.payedAmt;
      }

      this.subpackageDetail = {
        ...this.subpackageDetail,
        bookBalance: this.$calc.expr(
          `${this.contractInfoObj.sumPayedAmt || 0}-${num}-${designPayedAmt}`
        )
      };

      console.log("账面余额结果：" + this.subpackageDetail.bookBalance);
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          // 剔除分包合同明细的空数据
          // 校验合同详情，合同金额明细，合同分包明细是否完整填入
          // 如果没有id ，则新增

          let contractAmt = 0;
          let sumInvoiceAmt = 0;
          let sumPayedAmt = 0;

          this.amtCateList
            .filter(item => item.delStatus !== 1)
            .forEach(item => {
              if (
                typeof item.contractAmt === "number" &&
                !isNaN(item.contractAmt)
              ) {
                contractAmt = accurate.add(contractAmt, item.contractAmt);
              }

              if (
                typeof item.invoiceAmt === "number" &&
                !isNaN(item.invoiceAmt)
              ) {
                sumInvoiceAmt = accurate.add(sumInvoiceAmt, item.invoiceAmt);
              }

              if (typeof item.payedAmt === "number" && !isNaN(item.payedAmt)) {
                sumPayedAmt = accurate.add(sumPayedAmt, item.payedAmt);
              }
            });

          let params = {
            ...values,
            ...this.contractInfoObj,

            pid: this.msg.pid,
            projectName: this.msg.projectName,
            designCode: this.msg.designCode,

            historyId: this.hid,

            contractAmt,
            sumInvoiceAmt,
            sumPayedAmt,

            amtCateList: this.amtCateList.map(item => {
              return {
                ...item,
                pid: this.id || "",
                projectId: this.msg.pid,
                key: undefined
              };
            }),

            subContractList: this.subContractList.map(item => {
              return {
                ...item,
                pid: this.id || "",
                projectId: this.msg.pid,
                key: undefined
              };
            }),

            invoiceList: this.invoiceList.map(item => {
              return {
                ...item,
                code: item.code || "",
                type: item.type || "",
                amount: item.amount || "",
                date: item.date || "",
                pid: this.id || "",
                projectId: this.msg.pid,
                attachments: item.attachments
                  ? JSON.stringify(item.attachments)
                  : null,
                key: undefined
              };
            })
          };
          if (this.id) {
            params.id = this.id;
          }

          this.loading = true;
          submit(params)
            .then(() => {
              this.getDetail();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    // 草稿相关
    addDraft() {
      this.$emit("addDraft");
    },

    saveDraft() {
      this.form.validateFields((err, values) => {
        let params = {
          ...values,
          ...this.contractInfoObj,

          pid: this.msg.pid,
          projectName: this.msg.projectName,
          designCode: this.msg.designCode,

          historyId: this.hid,

          amtCateList: this.amtCateList.map(item => {
            return {
              ...item,
              pid: this.id || "",
              projectId: this.msg.pid,
              key: undefined
            };
          }),

          subContractList: this.subContractList.map(item => {
            return {
              ...item,
              pid: this.id || "",
              projectId: this.msg.pid,
              key: undefined
            };
          }),

          invoiceList: this.invoiceList.map(item => {
            return {
              ...item,
              code: item.code || "",
              type: item.type || "",
              amount: item.amount || "",
              date: item.date || "",
              pid: this.id || "",
              projectId: this.msg.pid,
              attachments: item.attachments
                ? JSON.stringify(item.attachments)
                : null,
              key: undefined
            };
          })
        };
        if (this.id) {
          params.id = this.id;
        }

        this.$emit("updateDraft", params);
      });
    },

    onSelectDraft(content) {
      this.setData(content);
    }
  }
};
</script>

<style lang="less" scoped>
.file-title {
  width: 15%;
  display: flex;
  margin: 20px 0px 10px;

  .line {
    width: 5px;
    margin-right: 10px;
    background-color: #1890ff;
  }
}

.content {
  display: flex;
  justify-content: space-between;

  .chart {
    width: 35%;
    background-color: #fafafa;
  }
}

.left-part {
  width: 60%;
}

.right-part {
  width: 35%;
}

.center {
  margin-top: 80px;
}
</style>

<style lang="less">
#income-expense-account {
  .ant-input-group-addon {
    padding: 0;
  }

  .content {
    .ant-form-item {
      margin-bottom: 0;

      .ant-input {
        border: none;
        text-align: center;
      }
    }
  }

  .ant-input-number {
    border: none;
    width: 100%;

    .ant-input-number-input {
      text-align: center;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        border: none;
      }
    }
  }
}
</style>
