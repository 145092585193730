<template>
  <div style="height: 100%" id="contract-assets-doughnut"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    sumInvoiceAmt: {},
    sumPayedAmt: {}
  },
  watch: {
    sumInvoiceAmt() {
      this.draw([
        {
          value: this.sumInvoiceAmt,
          name: "累计开票金额",
          key: "sumInvoiceAmt"
        },
        {
          value: this.sumPayedAmt,
          name: "累计回款金额",
          key: "sumPayedAmt"
        }
      ]);
    },
    sumPayedAmt() {
      this.draw([
        {
          value: this.sumInvoiceAmt,
          name: "累计开票金额",
          key: "sumInvoiceAmt"
        },
        {
          value: this.sumPayedAmt,
          name: "累计回款金额",
          key: "sumPayedAmt"
        }
      ]);
    }
  },

  mounted() {
    this.chart = echarts.init(
      document.getElementById("contract-assets-doughnut")
    );

    this.draw([
      {
        value: 0,
        name: "累计开票金额",
        key: "sumInvoiceAmt"
      },
      {
        value: 0,
        name: "累计回款金额",
        key: "sumPayedAmt"
      }
    ]);
  },

  destroyed() {
    this.chart?.dispose();
  },

  methods: {
    draw(data) {
      this.chart?.clear();

      let color = ["rgb(79,120,238)", "rgb(226,236,246)"];

      let setLabel = data => {
        let opts = [];
        for (let i = 0; i < data.length; i++) {
          let item = {};
          item.name = data[i].name;
          item.value = data[i].value;
          item.label = {
            //控制引导线上文字颜色和位置,此处a是显示文字区域，b做一个小圆圈在引导线尾部显示
            show: true,
            //a和b来识别不同的文字区域
            formatter: [
              "{a|{c}}", //引导线上面文字 ,{c} 为值，a为占位符
              "{b|}", //引导线下面文字 ，b为占位符
              "{c|{b}}" //引导线下面文字
            ].join("\n"), //用\n来换行
            rich: {
              a: {
                left: 20,
                padding: [0, -50, 2, -50],
                color: "#000",
                fontSize: 16,
                fontWeight: 800
              },
              b: {
                // 圆点style
                height: 5,
                width: 5,
                lineHeight: 5,
                marginBottom: 0,
                padding: [0, -5],
                borderRadius: 5,
                backgroundColor: color[i] // 圆点颜色和饼图块状颜色一致
              },
              c: {
                left: 20,
                padding: [2, -80, 0, -80],
                color: "#000"
              }
            }
          };

          opts.push(item);
        }
        return opts;
      };

      let option = {
        //环形颜色
        color: ["#ffb616", "#ccc"],
        legend: {
          show: false
        },
        series: [
          {
            color,
            name: "开票回款比例",
            type: "pie",
            radius: ["40%", "50%"],
            avoidLabelOverlap: false,
            labelLine: {
              show: true,
              length: 15, // 第一段线 长度
              length2: 100, // 第二段线 长度
              align: "right"
            },
            data: setLabel(data)
          }
        ]
      };

      this.chart?.setOption(option);
    }
  }
};
</script>